import React from "react";
import cards from "../images/cards_icon.svg";
import { useNavigate } from "../hook/useNavigate";
import { WatchButton } from "./WatchButton";
import { OurWhitepaper } from "./OurWhitepaperButton";

function Header() {
  const { navNewTab } = useNavigate()

  return (
    <header className="max-w-[1380px] mx-auto pt-8 lg:pt-[100px] px-4 xl:px-0 header_bg">
      <div style={{ paddingTop: "1px" }} className="flex flex-col items-center justify-center gap-8 max-w-[650px] mx-auto">
        {/* BRAND NAME TEXT */}

        {/* TITLE */}
        <h1 style={{ marginTop: "0px" }} className="text-[28px] lg:text-[62px] leading-[normal] lg:leading-[72px] lg:-tracking-[2.56px] font-bold lg:font-semibold text-center max-w-[350px] lg:max-w-none">
          Guaranteed Fairness Next-Gen Online Poker
          <br></br>
          <br></br>

        </h1>

        {/* PARAGRAPH */}
        <p className="max-w-[343px] md:max-w-[500px] mx-auto text-white-200 text-lg leading-[28.2px] tracking-[0.36px] font-medium text-center">
          Connect Your Wallet and Play now
        </p>

        {/* BUTTONS */}
        <div className="w-full flex flex-col-reverse md:flex-row items-center md:items-baseline justify-center gap-4">
          {/* CONNECT BUTTON AND TEXT*/}
          <div className="w-full md:w-fit flex flex-col items-center justify-center">
            {/* CONNECT BUTTON */}
            <button onClick={() => navNewTab('http://play.ztpoker.com')}
              style={{
                minWidth: "200px"
              }}
              className="hidden lg:flex w-full md:w-fit flex items-center justify-center gap-2 px-[25px] py-2.5 text-xs font-semibold text-black-200 border border-blue-100 rounded-full bg-linear-gradient-blue transition-all hover:transition-all hover:shadow-[0_4px_4px_0px_rgba(251,254,255,0.05)inset,0px_4px_12px_0px_rgba(77,222,255,0.50)] group">
              <span className="transition-all translate-y-0 group-hover:transition-all group-hover:-translate-y-[1.3px]">
                Play Now
              </span>
              <img src={cards} alt="cards" />
            </button>

            {/* CONNECT BUTTON */}
            <button className="lg:hidden w-full md:w-fit flex items-center justify-center gap-2 px-[25px] py-2.5 text-xs font-semibold text-black-200 border border-blue-100 rounded-full bg-linear-gradient-blue transition-all hover:transition-all hover:shadow-[0_4px_4px_0px_rgba(251,254,255,0.05)inset,0px_4px_12px_0px_rgba(77,222,255,0.50)] group">
              <span className="transition-all translate-y-0 group-hover:transition-all group-hover:-translate-y-[1.3px]">Mobile Play Coming Soon</span>
              <img src={cards} alt="cards" />
            </button>

            {/* NO SIGNUP TEXT */}
            <div className="lg:hidden text-white-200 text-sm leading-[24.9px] font-medium pt-2.5">
              <span className=" text-blue-100 font-bold"> </span> Available on Desktop
            </div>

            {/* NO SIGNUP TEXT */}
            <div className="text-white-200 text-xs leading-[24.9px] font-medium pt-2.5">
              <span className=" text-blue-100 font-bold">* </span> No Download/Signup
              Needed
            </div>

          </div>

          {/* WATCH VIDEO BUTTON */}
          <div className="hidden">
            <OurWhitepaper />
          </div>

        </div>
      </div>

      {/* ARROW DOWN BUTTON */}
      {/* <button className="w-fit mx-auto flex pt-9">
          <img className="shadow-[0_4px_12px_0px_rgba(77,222,255,0.50)] rounded-full transition-all hover:rotate-[20deg] hover:scale-125 hover:transition-all" src={arrow_down} alt="arrow-down" />
        </button> */}
    </header >
  );
}

export default Header;
